.card {
    &__content {
        &--contact {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin-top: 10px;
        }
    }

    &__contact-item {
        flex: 1 1 0;
        max-width: 280px;
        margin-left: 30px;

        &:first-child {
            margin-left: 0;
        }

        &__link {
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;

            &:hover, &:focus {
                text-decoration-color: $color-text;
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .card {

        &__content {
            &--contact {
                flex-wrap: wrap;
            }
        }

        .card__contact-item {
            flex: 1 1 0;
            max-width: auto;
    
            &--buttons {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex: 1 1 100%;
                max-width: 100%;
                margin-left: 0;
                margin-top: 20px;
                flex-wrap: wrap;
                column-gap: 30px;
            }
        }

    }

}


// 640
@media screen and (max-width: $small) {

    .card {

        &__content {
            &--contact {
                flex-wrap: wrap;
            }
        }

        .card__contact-item {
            flex: 1 1 100%;
            max-width: 100%;
            margin-left: 0;
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }
    
            &--buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
            }
        }

    }

}
