.home {
    &__title {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start);
        font-size: toRem(50);
        line-height: toRem(55);
        font-weight: $font-weight-black;
        margin: 0;
        
        &:before {
            content: none;
        }

        &__sub {
            position: relative;
            font-size: toRem(45);
            line-height: toRem(50);
            font-weight: $font-weight-light;
            margin-left: 135px;

            &:before {
                content: "";
                @include absolute($top: 30px, $right: calc(100% + 20px));
                @include size(100vw, 1px);
                background-color: $color-gray;
            }
        }
    }
}

//======================================================================================================
// Search bar + Access
//======================================================================================================

.searchaccess {
    position: relative;
    z-index: 2;
    padding: 80px 0;

    &__container {
        @include flex($direction: row, $justifyContent: space-between);
    }
}

.searchbar-home {
    margin-top: 20px;
    width: calc(50% - 15px);

    &__title {
        position: relative;
        font-size: toRem(45);
        line-height: toRem(50);
        margin-bottom: 50px;
        font-weight: $font-weight-light;

        &__sub {
            font-size: toRem(45);
            line-height: toRem(50);
            font-weight: $font-weight-black;
            margin-left: 100px;
        }

        &__shape {
            @include absolute($top: -20px, $left: 380px);
            @include size(80px, 55px);
        }
    }

    &__bar {
        position: relative;
        z-index: 1;
    }

    #searchInputHome {
        border-radius: 20px 20px 0 0;
        background-color: $color-light;
        border: 0;
        height: 75px;
        border-bottom: 2px $border-style $color-second;

        &::placeholder {
            color: $color-text;
        }

        &:focus::placeholder {
            opacity: 1;
        }
    }

    .searchResult {
        @include absolute($top: 73px, $right: 0, $left: 0);
        border-radius: 0 0 20px 20px;
        border: 0;
        margin: 0 auto;
        overflow: hidden;

        li {
            border-top: $border-width $border-style $color-second;
            background-color: $color-light;
        }
    }

    .search-svg {
        border: 0;
        background-color: unset;
        
        svg {
            height: 45px;
            width: 45px;
        }
    }
}

.access {

    &__list {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        width: calc(50% - 180px);
        margin-right: 50px;
        gap: 20px 60px;
    }

    &__item {

        &:first-child {
            .access__circle {
                transform: rotate(180deg);
            }
        }

        &:nth-child(2), &:nth-child(4), &:nth-child(6) {
            .access__circle {
                display: none;
            }
        }
    }
    
    &__link {
        position: relative;
        @include size(95px, 125px);
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        gap: 10px;

        &:hover, &:focus {
            .access__picto {
                background-color: $color-main;

                path[fill="#030B28"] {
                    fill: $color-white;
                }
            }
        }
    }

    &__picto {
        @include size(85px);
        background-color: $color-gray;
        border-radius: $border-radius--round;
        transition: {
            property: background-color, fill;
            duration: $duration;
            timing-function: $timing;
        }

        path {
            transition: fill $duration $timing;
        }
    }

    &__circle {
        @include size(85px);
        @include absolute($top: 0, $right: 0, $left: 0);
        margin: auto;
        fill: $color-main;
        opacity: .5;
    }

    &__label {
        font-size: toRem(12);
        line-height: toRem(15);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        text-align: center;
    }
}


//======================================================================================================
// Posts Events (Zone Mixte)
//======================================================================================================
.postsevents {

    &__section {
        position: relative;
        z-index: 1;
    }

    &__container {
        position: relative;
    }

    &__title {
        margin-bottom: 30px
    }

    &__items {
        @include size(calc(100% - 80px), auto);
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 30px;
    }

    &__shape {
        @include absolute($top: -25px, $right: -70px);
        @include size(250px, 355px);
    }

    &__circle {
        @include absolute($top: 295px, $left: -80px);
        @include size(260px);
        fill: $color-gray;
    }

    &__type {
        @include absolute($top: 20px, $right: 20px);
        @include size(fit-content, 40px);
        border-radius: $border-radius;
        padding: 10px 10px 10px 26px;
        text-transform: uppercase;
        font-size: toRem(14);
        line-height: toRem(20);

        &:before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: 10px);
            @include size(8px);
            background-color: $color-white;
            border-radius: $border-radius--round;
            margin: auto 0;
        }
    }

    &__item {
        position: relative;
        @include size(530px, 330px);
        border-radius: $border-radius;
        background-color: $color-gray;

        &:before {
            content: '';
            @include absolute($top: 0, $left: 0);
            @include size(530px, 330px);
            margin: auto;
            border-radius: $border-radius;
            background-color: #0000004D;
            transition: {
                property: top, left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &--post {

            &:before {
                background-color: $color-third;
            }

            .postsevents {
                &__type {
                    background-color: $color-third;
                }

                &__image {

                    &--notimg {
                        background: url($urlSpriteImage + '/base-icons/posts-icons/post.svg') no-repeat $color-bg--image;
                        background-position: center;
                    }
                }
            }
        }

        &--event {

            &:before {
                background-color: $color-gray;
            }

            .postsevents {
                &__type {
                    background-color: $color-gray;
                }

                &__image {

                    &--notimg {
                        background: url($urlSpriteImage + '/events-icons/events.svg') no-repeat $color-bg--image;
                        background-position: center;
                    }
                }
            }
        }
        
        &:nth-child(2) {
            margin-left: -80px
        }

        &:nth-child(3) {
            margin-left: 80px
        }

        &:hover, &:focus {
            
            &:before {
                top: 5px;
                left: 5px;
            }

            .postsevents {

                &__arrow {
                    transform: rotate(-90deg);
                }

                &__image {
                    &:before {
                        opacity: .7;
                    }
                }

                &__text {
                    max-height: 50px;
                    opacity: 1;
                }
            }
        }
    }

    &__image {
        position: relative;
        z-index: 0;
        @include size(530px, 330px);
        background-color: $color-gray;
        border-radius: 10px;
        margin-left: 0;
        margin-top: 0;
        overflow: hidden;
        transition: {
            property: margin;
            duration: $duration;
            timing-function: $timing;
        }

        img {
            @include size(100%);
            object-fit: cover;
        }

        &:before {
            content: "";
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            z-index: 1;
            background-color: $color-black;
            opacity: .3;
            margin: auto;
            transition: opacity $duration $timing;
        }
    }

    &__content {
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include absolute($right: 0, $bottom: 0, $left: 0);
        padding: 0 100px 25px 30px;
        color: $color-white;
        overflow: hidden;

        .postsevents {
            &__title {
                font-size: toRem(22);
                line-height: toRem(25);
                text-transform: uppercase;
                font-weight: $font-weight-black;
                margin: 5px 0;
            }

            &__text {
                max-height: 0;
                opacity: 0;
                transition: {
                    property: margin, opacity, max-height;
                    duration: $duration;
                    timing-function: $timing;
                };

                &--location {
                    display: flex;
                    align-items: center;
                    font-style: italic;
                    font-size: toRem(16);
                    line-height: toRem(25);
                    font-weight: $font-weight-normal;

                    svg {
                        @include size(15px);
                        fill: $color-white;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    &__date {
        font-size: toRem(18);
        line-height: toRem(25);
        font-weight: $font-weight-extrabold;
        text-transform: uppercase;
    }

    &__arrow {
        @include absolute($right: 25px, $bottom: 25px);
        @include size(30px);
        fill: $color-white;
        transition: transform $duration $timing;
    }

    &__buttons {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-end);
        gap: 30px;
        margin-top: 30px;
    }

    &__button {
        position: relative;
        padding: 15px 20px 15px 40px;
        margin: 0;
        font-size: toRem(16);
        line-height: toRem(20);
        background-color: $color-white;
        border-color: $color-white;
        color: $color-text;

        &:before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: 25px);
            @include size(8px);
            margin: auto 0;
            border-radius: $border-radius--round;
            transition: background-color $duration $timing;
        }

        &--posts {
            &:before {
                background-color: $color-third;
            }

            &:hover, &:focus {
                background-color: $color-third;

                &:before {
                    background-color: $color-white;
                }
            }
        }

        &--events {
            &:before {
                background-color: $color-gray;
            }
        
            &:hover, &:focus {
                background-color: $color-gray;
                border-color: $color-gray;

                &:before {
                    background-color: $color-white;
                }
            }
        }
    }
}


//======================================================================================================
// Quotidien
//======================================================================================================
.quotidien {

    &__section {
        position: relative;
        z-index: 0;
        padding-top: 80px;
        padding-bottom: 100px;

        &:before {
            content: '';
            z-index: -1;
            @include absolute($right: 0, $bottom: 0, $left: 0);
            @include size(100%, 805px);
            background-color: $color-main;
        }
    }

    &__title {
        color: $color-white;
        margin-bottom: 30px;

        &__shape {
            @include size(75px, 70px);
            @include absolute($top: 20px, $left: 345px);
        }
    }

    &__items {
        position: relative;
        z-index: 0;
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        gap: 30px;
    }

    &__circle {
        @include size(180px);
        @include absolute($top: -65px, $right: -50px);
        z-index: -1;
        fill: $color-third;
    }

    &__post {
        position: relative;
        z-index: 0;
        @include size(370px, 290px);
        border-radius: $border-radius;

        &:before {
            content: "";
            z-index: -1;
            @include absolute($top: 0, $left: 0);
            @include size(370px, 290px);
            border-radius: $border-radius;
            margin: 0;
            background-color: $color-third;
            transition: {
                property: top, left;
                duration: $duration;
                timing-function: $timing;
            }
        }

        .quotidien {
            &__title {
                margin: 0
            }
        }

        &:hover, &:focus {

            &:before {
                top: 5px;
                left: 5px;
            }

            .quotidien {
                &__text {
                    max-height: 75px;
                    opacity: 1;
                }

                &__image {
                    &:before {
                        opacity: .7;
                    }
                }

                &__arrow {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    &__image {
        position: relative;
        @include size(100%);
        border-radius: $border-radius;
        overflow: hidden;

        img {
            @include size(100%);
            object-fit: cover;
        }

        &--notimg {
            background: url($urlSpriteImage + '/base-icons/posts-icons/post.svg') no-repeat $color-bg--image;
            background-position: center;
        }

        &:before {
            content: '';
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            background-color: $color-black;
            margin: auto;
            opacity: .4;
            transition: opacity $duration $timing;
        }
    }

    &__type {
        @include absolute($top: 20px, $right: 20px);
        @include size(fit-content, 40px);
        border-radius: $border-radius;
        background-color: $color-third;
        padding: 10px 10px 10px 26px;
        text-transform: uppercase;
        font-size: toRem(14);
        line-height: toRem(20);

        &:before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: 10px);
            @include size(8px);
            background-color: $color-white;
            border-radius: $border-radius--round;
            margin: auto 0;
        }
    }
    
    &__content {
        z-index: 1;
        @include absolute($right: 0, $bottom: 0, $left: 0);
        padding: 0 80px 25px 25px;
        color: $color-white;
        overflow: hidden;

        .quotidien {
            &__title {
                font-size: toRem(20);
                line-height: toRem(25);
                text-transform: uppercase;
                font-weight: $font-weight-black;
            }

            &__text {
                max-height: 0;
                opacity: 0;
                text-transform: initial;
                transition: {
                    property: max-height, opacity;
                    duration: $duration;
                    timing-function: $timing;
                }
            }
        }
    }

    &__access {
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
        gap: 30px;

        &__link {
            @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
            gap: 10px;
            @include size(95px, 130px);
            color: $color-white;
            font-size: toRem(12);
            line-height: toRem(15);
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            text-align: center;

            svg {
                @include size(85px);
                fill: $color-white;
                background-color: #26335F;
                border: $border-width $border-style #26335F;
                ;
                border-radius: $border-radius--round;
                transition: {
                    property: background-color, border-color;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {
                color: $color-white;

                svg {
                    background-color: #475479;
                    border-color: #8A94AC;
                }
            }
        }
    }

    &__links {
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
        height: 290px;
        gap: 30px;
    }

    &__link {
        position: relative;
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-end);
        padding: 25px 75px 25px 25px;
        @include size(355px, 130px);
        border: $border-width $border-style $color-gray;
        border-radius: $border-radius;
        overflow: hidden;
        color: $color-white;
        font-size: toRem(18);
        line-height: toRem(20);
        font-weight: $font-weight-bold;
        background-color: $color-bg--transparent;
        transition: all $duration $timing;

        &--image {
            @include size(260px, 290px);
            border: 0;

            img {
                transform: scale(1);
                transition: transform $duration $timing;
            }

            &:before {
                content: '';
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                background-color: $color-black;
                opacity: 0;
                transition: opacity $duration $timing;
            }

            &:hover, &:focus {
                background-color: $color-bg--transparent;

                img {
                    transform: scale(1.05);
                }

                &:before {
                    opacity: .6;
                }
            }
        }
        
        &__label, &__text {
            z-index: 1;
        }

        &__label {
            text-transform: uppercase;
        }

        &__text {
            font-size: toRem(14);
            line-height: toRem(20);
            font-weight: $font-weight-normal;
            text-transform: unset;
            opacity: 0;
            margin-bottom: -40px;
            margin-top: 5px;
            transition: {
                property: opacity, margin-bottom;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &__img {
            z-index: -1;
            @include size(100%);
            object-fit: cover;
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
        }

        &:hover, &:focus {
            color: $color-white;
            background-color: #9CB0E526;
            border-color: #1a2344;

            .quotidien {

                &__link {

                    &__text {
                        margin-bottom: 0;
                        opacity: 1;
                    }
                }

                &__arrow {
                    transform: rotate(-90deg);
                }
            }
        }
    }    

    &__arrow {
        @include absolute($right: 25px, $bottom: 25px);
        @include size(30px);
        fill: $color-white;
        transition: transform $duration $timing;
    }
}


//======================================================================================================
// Kiosque
//======================================================================================================
.kiosque {

    &__section {
        margin-top: 100px;
    }

    &__container {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: space-between);
    }

    &__title {
        margin: 0 0 30px;
    }

    &__swiper {
        position: relative;
        width: calc(50% - 110px);
        
        .swiper-container {
            overflow: unset;
        }
    
        .swiper-slide {
            @include flex($direction: row, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
            background-color: $color-white;
            gap: 35px;
        }
    }

    &__navigation {
        @include absolute($bottom: 0, $left: 275px);
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        gap: 10px;

        .swiper-button {
            position: unset;
            @include size(40px);
            margin: 0;
            padding: 0;
            border: 0;
            background-color: unset;

            &:before, &:after {
                content: unset
            }

            svg {
                @include size(40px);
                fill: $color-text;
                background-color: $color-third;
                border-radius: $border-radius;
                border: 0;
                transition: {
                    property: background-color, fill;
                    duration: $duration;
                    timing-function: $timing;
                }
            }

            &:hover, &:focus {

                svg {
                    fill: $color-white;
                    background-color: $color-dark;
                }
            }
        }
    }

    &__image {
        @include size(240px, 340px);
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: $shadow;

        &--notimg {
            position: relative;
            background-color: $color-gray;

            svg {
                @include size(80px);
                @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
                margin: auto
            }
        }

        img {
            @include size(100%);
            object-fit: cover;
        }
    }

    &__content {
        @include size(200px, 100%);
        padding-top: 25px;

        .kiosque {

            &__title {
                font-size: toRem(25);
                line-height: toRem(30);
                font-weight: $font-weight-bold;
                margin: 0 0 15px;
            }

            &__infos {
                font-size: toRem(16);
                line-height: toRem(20);
                font-weight: $font-weight-light;
                text-transform: capitalize;
            }
        }
    }

    &__cate {
        position: relative;
        z-index: 0;
        width: calc(50% - 100px);
        margin-right: 100px;
        @include flex($direction: row, $wrap: wrap, $alignItems: flex-start, $justifyContent: space-between);
        gap: 35px;

        &:before {
            content: '';
            z-index: -1;
            @include absolute($top: -40px, $left: 60px);
            @include size(100vw, 300px);
            background-color: $color-light;
            border-radius: 15px 0 0 15px;
        }
    }

    &__shape {
        @include absolute($top: -110px, $right: -140px);
        @include size(140px, 95px);
        z-index: 1;
        pointer-events: none;
    }

    &__buttons {
        margin-top: 40px;
        @include flex($direction: row, $wrap: wrap, $alignItems: center);
        gap: 10px;
    }

    &__button {
        @include size(40px);

        svg {
            @include size(38px);
            fill: $color-dark;
            background-color: $color-bg--transparent;
            border: $border-width $border-style $color-dark;
            border-radius: $border-radius;
            transition: {
                property: background-color, fill;
                duration: $duration;
                timing-function: $timing;
            }
        }

        &:hover, &:focus {

            svg {
                background-color: $color-dark;
                fill: $color-white;
            }
        }
    }

    &__allbutton {
        position: relative;
        font-size: toRem(16);
        line-height: toRem(20);
        margin: -52px 0 0 auto;
        padding: 15px 35px 15px 55px;

        &:before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: 35px);
            @include size(8px);
            margin: auto 0;
            background-color: $color-white;
            border-radius: $border-radius--round;
            transition: background-color $duration $timing;
        }

        &:hover, &:focus {
            color: $color-text;
            background-color: $color-third;

            &:before {
                background-color: $color-text;
            }
        }
    }
}


//======================================================================================================
// Retour Image
//======================================================================================================
.retourimg {

    &__section {
        position: relative;
        z-index: 1;
        margin-top: 140px;
        margin-bottom: 100px;
    }

    &__title {
        position: relative;

        &__shape {
            @include absolute($top: -20px, $left: 230px);
            @include size(70px);
        }
    }

    &__circle {
        z-index: -1;
        @include absolute($top: -50px, $left: calc(50% - 245px));
        @include size(285px, 250px);
        fill: $color-gray;
    }

    &__container {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-end);
        @include size(100%, 615px);
        gap: 30px;
    }

    &__item {
        position: relative;
        background-color: $color-gray;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            display: block;
            @include size(100%);
            object-fit: cover;
        }

        &:nth-child(2) {
            @include size(415px, 220px);
        }

        &:nth-child(3) {
            @include size(415px, 230px);
        }

        &:nth-child(4) {
            @include size(360px, 260px);
        }

        &:nth-child(5) {
            @include size(360px, 320px);
        }

        &:nth-child(6) {
            @include size(335px, 385px);
        }

        &:nth-child(7) {
            @include size(335px, 195px);
        }

        &:hover, &:focus {
            .retourimg {
                &__content {
                    opacity: 1;
                    gap: 5px;
                }
            }
        }
    }

    &__content {
        @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        color: $color-white;
        font-size: toRem(18);
        line-height: toRem(20);
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        text-align: center;
        gap: 50px;
        margin: auto;
        padding: 0 50px;
        opacity: 0;
        background-color: rgba($color-black, .6);
        transition: {
            property: gap, opacity;
            duration: $duration;
            timing-function: $timing;
        }

        svg {
            @include size(40px);
            fill: $color-white;
            transform: rotate(-90deg);
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .home {
        
        &__title {
            font-size: toRem(45);
            line-height: toRem(50);
    
            &__sub {
                font-size: toRem(40);
                line-height: toRem(45);
    
                &:before {
                    top: 24px;
                }
            }
        }
    }

    // Search Bar + Access
    .searchbar-home {
        width: 50%;

        &__title {
            font-size: toRem(40);
            line-height: toRem(45);

            &__shape {
                left: 335px;
            }
        }
    }

    .access {
        &__list {
            gap: 20px 50px;
            width: calc(50% - 85px);
            margin-right: 20px;
        }
    }

    // Posts Events (Zone Mixte)
    .postsevents {

        &__shape {
            right: -90px;
        }

        &__type {
            top: 15px;
            right: 15px;
        }

        &__items {
            gap: 20px;
            width: calc(100% - 40px);
        }

        &__item {
            @include size(440px, 275px);

            &:before {
                @include size(440px, 275px);
            }
            
            &:nth-child(2) {
                margin-left: -40px;
            }

            &:nth-child(3) {
                margin-left: 40px;
            }
        }

        &__image {
            @include size(440px, 275px);

            &:before {
                @include size(440px, 275px);
            }
        }

        &__content {
            max-width: 500px;
        }
    }

    // Quotidien
    .quotidien {

        &__section {

            &:before {
                height: 775px;
            }
        }

        &__circle {
            right: -60px;
            top: -75px;
        }

        &__title {
            margin-bottom: 40px;
        }

        &__items {
            gap: 20px;
        }

        &__type {
            top: 15px;
            right: 15px;
        }
        
        &__content {
            padding-right: 60px
        }

        &__arrow {
            right: 20px;
            bottom: 20px;
        }

        &__post {
            @include size(330px, 290px);

            &:before {
                @include size(330px, 290px);
            }
        }

        &__access {
            &__link {
                gap: 15px;
                width: 90px;
            }
        }

        &__links {
            gap: 20px;
        }

        &__link {
            width: 250px;
            padding: 20px 55px 20px 20px;
        
            &--image {
                width: 210px;
            }
            
            &__label {
                font-size: toRem(16);
                line-height: toRem(20);
            }

            &__text {
                margin-bottom: -60px;
            }
        }
    }

    // Kiosque
    .kiosque {

        &__shape {
            right: -100px;
        }

        &__swiper {
            width: calc(50% - 40px);

            .swiper-slide {
                gap: 20px;
            }
        }

        &__cate {
            width: calc(50% - 40px);
            margin-right: 60px;
            gap: 20px;

            &::before {
                height: 260px;
            }
        }

        &__image {
            @include size(210px, 300px);
        }

        &__navigation {
            left: 230px;
        }

        &__buttons {
            margin-top: 30px;
        }

        &__allbutton {
            padding: 15px 15px 15px 30px;

            &:before {
                left: 15px;
            }
        }
    }

    // Retour en image (Zone libre)
    .retourimg {

        &__section {
            margin-top: 150px;
        }

        &__circle {
            left: calc(50% - 215px);
        }

        &__title {
            margin-bottom: 10px;

            &__shape {
                top: -25px;
            }
        }

        &__container {
            gap: 20px;
            height: 520px;
        }

        &__item {
            
            &:nth-child(2) {
                @include size(325px, 180px);
            } 
            
            &:nth-child(3) {
                @include size(325px, 190px);
            }
            
            &:nth-child(4) {
                @include size(305px, 220px);
            }
            
            &:nth-child(5) {
                @include size(305px, 280px);
            }
            
            &:nth-child(6) {
                @include size(270px, 340px);
            }
            
            &:nth-child(7) {
                @include size(270px, 160px);
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    // Search bar + Access
    .searchaccess {
    padding-top: 70px;

        &__container {
            flex-direction: column;
        }
    }

    .searchbar-home {
        width: 100%;
        margin: 0;
    }

    .access {

        &__list {
            width: 100%;
            margin: 40px auto 5px;
            justify-content: center;
            gap: 25px;
            flex-wrap: wrap;
        }
    }


    // Posts + Events
    .postsevents {

        &__shape {
            @include size(165px, 235px);
            top: 0;
            right: -60px;
        }

        &__circle {
            display: none;
        }

        &__items {
            width: 100%;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
        }

        &__item {
            @include size(100%, 240px);
            margin: 0!important;

            &:before {
                content: none;
            }
        }

        &__image {
            @include size(100%, 240px);
            margin: 0;

            &:before {
                @include size(100%, 240px);
            }
        }

        &__type {
            height: 30px;
            padding: 5px 10px 5px 25px;

            &:before {
                @include size(5px);
            }
        }

        &__content {
            padding: 0 15px 25px;

            .postsevents {

                &__title {
                    font-size: toRem(15);
                    line-height: toRem(20);
                }

                &__text {
                    font-size: toRem(14);
                    line-height: toRem(20);
                    max-height: unset;
                    height: auto;
                    margin: 0;
                    opacity: 1
                }
            }
        }

        &__date {
            font-size: toRem(16);
            line-height: toRem(20);
        }

        &__arrow {
            display: none;
        }

        &__buttons {
            justify-content: flex-start;
        }
    }

    // Quotidien
    .quotidien {

        &__section {
            padding: 80px 0;
            
            &:before {
                height: 1220px;
            }
        }

        &__circle {
            top: -65px;
        }

        &__title {
            margin-bottom: 30px;
        }

        &__items {
            align-items: flex-start;
        }

        &__post {
            @include size(100%, 240px);

            &:before {
                content: none;
            }

            .quotidien {
                &__arrow {
                    display: none;
                }
            }
        }

        &__content {
            padding: 15px;

            .quotidien {

                &__title {
                    font-size: toRem(18);
                    line-height: toRem(25);
                }

                &__text {
                    font-size: toRem(14);
                    line-height: toRem(20);
                    max-height: unset;
                    height: auto;
                    opacity: 1;
                    margin: 0;
                }
            }
        }

        &__access {
            margin-left: 30px;
        }

        &__links {
            width: calc(100% - 150px);
            height: unset;
            margin-left: 10px;
            gap: 20px;
        }

        &__link {
            width: 100%;

            &:nth-child(1) {
                margin-bottom: 10px;
            }
        
            &--image {
                width: calc(100% + 150px);
                height: 160px;
                margin-left: -150px;
            }

            &__text {
                margin-bottom: 0;
                height: auto;
                opacity: 1;
            }

            .quotidien {
                &__arrow {
                    transform: rotate(-90deg);
                }
            }
        }
    }

    // Kiosque
    .kiosque {

        &__section {
            position: relative;
            margin-top: 80px;

            &:before {
                content: "";
                @include absolute($right: 0, $bottom: 80px, $left: 0);
                @include size(100%, 280px);
                border-radius: unset;
                background-color: $color-light;
            }
        }

        &__title {
            margin-bottom: 15px;

            &__sub {
                margin-left: 95px;
            }
        }

        &__container {
            flex-direction: column;
            align-items: flex-start;
        }

        &__swiper {
            width: 360px;

            .swiper-slide {
                gap: 15px;
                justify-content: flex-start
            }

            & .kiosque {
                &__image {
                    @include size(145px, 205px);
                }

                &__content {
                    width: 200px;
                    padding-top: 20px;

                    .kiosque {
                        &__title {
                            font-size: toRem(18);
                            line-height: toRem(20);
                            margin-bottom: 5px;
                        }
        
                        &__infos {
                            font-size: toRem(14);
                            line-height: toRem(20);
                        }
                    }
                }
            }
        }

        &__cate {
            width: 100%;
            margin: 0;
            gap: 15px;
            padding-bottom: 30px;
            flex-direction: column;
            margin-top: 40px;
            
            &:before {
                content: none;
            }
        }

        &__shape {
            left: 240px;
            right: unset;
            top: 105px;
        }

        &__content {
            padding-top: 0;
            width: 100%;

            .kiosque {
                &__title {
                    font-size: toRem(22);
                    line-height: toRem(25);
                    margin-bottom: 5px;
                }

                &__infos {
                    font-size: toRem(16);
                    line-height: toRem(20);
                }
            }
        }

        &__navigation {
            left: 160px;
        }

        &__buttons {
            margin-top: 20px;
        }

        &__allbutton {
            margin: 30px 0 0;
        }
    }

    // Retour en Image
    .retourimg {

        &__section {
            margin-top: 80px;
            margin-bottom: 80px;
        }

        &__circle {
            display: none;
        }

        &__title {
            width: 100%;
            margin-bottom: 15px;
        }

        &__container {
            height: auto;
            flex-direction: row;
        }

        &__item {
            @include size(calc(50% - 10px)!important, 200px!important);
        }

        &__content {
            display: none;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .home {
        &__title {
            font-size: toRem(35);
            line-height: toRem(40);

            &__sub {
                font-size: toRem(30);
                line-height: toRem(35);
                margin-left: 95px;
                margin-top: -5px;
            }
        }
    }

    // Searchbar + Access 
    .searchaccess {
        padding-top: 50px;
    }

    .searchbar-home {
        #searchInputHome {
            padding: 15px 70px 15px 15px;
            height: 50px;
        }

        &__title {
            font-size: toRem(30);
            line-height: toRem(35);
            margin-bottom: 30px;

            &__sub {
                margin-left: 60px;
                margin-top: 0
            }

            &__shape {
                @include size(50px, 35px);
                top: 0;
                left: 255px
            }
        }
    }

    .access {

        &__link {
            @include size(85px, 115px);
        }

        &__list {
            max-width: 460px;
            gap: 15px 50px;
            margin-top: 50px;
        }

        &__circle, &__picto {
            @include size(75px);
        }
    }
    

    // Posts + Events
    .postsevents {

        &__item {
            height: 200px;
        }

        &__title {
            &__sub {
                margin-top: 0;
            }
        }

        &__shape {
            display: none;
        }

        &__image {
            height: 200px;

            &:before {
                height: 200px;
            }
        }
        
        &__content {
            padding-bottom: 15px;
        }

        &__buttons {
            gap: 15px 30px;
            align-items: flex-start;
            flex-direction: column;

        }

        &__button {
            width: 300px;
            padding-left: 65px;
            padding-right: 40px;

            &--posts {
                &:before {
                    left: 45px;
                }
            }

            &--events {
                &:before {
                    left: 50px;
                }
            }
        }
    }

    // Quotidien
    .quotidien {

        &__section {
            &:before {
                height: 1280px;
            }
        }
        
        &__title {
            &__shape {
                @include size(60px, 55px);
                top: 10px;
                left: 255px;
            }
        }

        &__circle {
            display: none;
        }

        &__post {
            height: 220px;
        }

        &__access {
            width: 100%;
            flex-direction: row;
            justify-content: flex-start;
            margin-left: 0;
            gap: 20px;

            &__link {
                @include size(auto, 65px);
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                svg {
                    @include size(65px);
                }
            }

            &__label {
                width: 65px;
                text-align: left;
            }
        }

        &__links {
            width: 100%;
            margin-left: 0;
        }

        &__link {
            padding: 0 75px 15px 15px;

            &:first-child {
                margin: 0;
            }

            &--image {
                width: 100%;
                margin-left: 0;
            }
        }
    }

    // Kiosque
    .kiosque {

        &__title {
            margin-bottom: 30px;

            &__sub {
                margin-top: 0;
            }
        }

        &__swiper {
            width: 300px;
        }

        &__shape {
            @include size(80px, 55px);
            right: -20px;
            top: 130px;
        }
    }

    // Retour Image
    .retourimg {

        &__item {
            @include size(100%!important, 175px!important);
        }
    }
}


// 320
@media screen and (max-width: $x-small) {

    // Searchbar + Access
    .access {
        &__list {
            gap: 15px 20px;
        }
    }
}
