.breadcrumb {
    
    ul {
        display: block;
        padding: 15px 0;
    }

    li {
        position: relative;
        display: inline;
        color: $color-text;
        font-size: toRem(16);
        line-height: toRem(25);

        a {
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            text-underline-offset: 2px;

            &:hover, &:focus {
                text-decoration-color: $color-text
            }
        }

        svg {
            display: inline-block;
            width: 20px;
            height: 22px;
            margin: 0 5px;
            fill: $color-text;
            vertical-align: text-top;
        }
    }
}



//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .breadcrumb {
        li svg {
            margin: 0;
        } 
    }

}


// 640
@media screen and (max-width: $small) {

    .breadcrumb {
        li svg {
            margin: 0 -1px;
        } 
    }

}
