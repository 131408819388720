header {
    position: relative;
    z-index: 20;
    background: $color-main;
    border-radius: 0px 0px 30px 30px;

    & ~ main {
        margin-top: 0;
    }

    .sticky {
        position: fixed;
        z-index: -1;
        top: 335px;
        left: calc(50% + 645px);
        @include flex($direction: column, $wrap: wrap, $alignItems: center, $justifyContent: center);
        @include size(40px, 190px);
        gap: 10px;
        transition: top $duration $timing;

        &:before {
            content: "";
            position: fixed;
            top: 0;
            left: calc(50% + 665px);
            height: 320px;
            width: 1px;
            background-color: $color-main;
            transition: height $duration $timing;
        }

        &__btn {
            @include size(40px);
            background-color: $color-white;
            border-radius: $border-radius;
            margin: 0;
            padding: 0;
            border: 0;
            transition: background-color $duration $timing;
            
            svg {
                @include size(40px);
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-third;
            }
        }
    }

    #headerImg {
        position: absolute;
        width: 100%;
        height: 260px;
        border-radius: 0 0 29px 29px;
        overflow: hidden;

        &:before {
            content: "";
            @include absolute($top: 0, $right: 0, $bottom: 0, $left: 0);
            @include size(100%);
            margin: auto;
            background-color: #0000004D;
        }

        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
    }

    &.header {
        &--fixed {
            position: fixed;
            top: 0;
            width: 100%;
            animation: $duration linear 0s headerfix;

            .sticky {
                top: 175px;
                
                &:before {
                    height: 160px;
                }
            }

            @keyframes headerfix {
                from {
                  top: -100px;
                }
                to {
                  top: 0;
                }
            }

            & ~ main {
                margin-top: 260px;
            }

            #headerImg {
                display: none;
            }

            .header {
                &__container {
                    padding-top: 20px;
                    padding-bottom: 20px;
                }
            }

            .identity {
                @include size(177px, 60px);

                &__title {
                    @include size(177px, 60px);
                }

                svg {
                    @include size(177px, 60px);
                }
            }

            .nav-main--burger {
                & .nav-main-buttons {
                    &__menu {
                        &--close {
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    & .header {

        &__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 80px;
            padding-bottom: 80px;
        }

        &__right {
            @include flex($direction: row, $wrap: wrap, $alignItems: center);
            column-gap: 20px;
        }
    }
}

//======================================================================================================
// Identity
//======================================================================================================
.identity {
    @include flex($alignItems: center, $justifyContent: center);
    @include size(295px, 100px);
    color: $color-text;

    &:hover, &:focus {
        color: $color-second;
    }

    &__title {
        @include size(295px, 100px);
        margin: 0;
    
        & svg {
            @include size(295px, 100px);
    
            path[fill="#000000"] {
                fill: $color-white;
            }
        }
    }
}


//======================================================================================================
// Tools
//======================================================================================================
.tools {

    &__container {
        display: flex;
        justify-content: flex-end;
    }

    &__content {
        position: relative;
        z-index: 0;
        display: flex;
        column-gap: 15px;
        justify-content: center;
        background-color: $color-bg--transparent;
        pointer-events: auto;
    }

    &__item {
        @include flex($justifyContent: center, $alignItems: center);
        @include size(50px);
        padding: 0;
        margin: 0;
        background-color: $color-bg--transparent;
        backdrop-filter: blur(10px);
        border: $border-width $border-style $color-white;
        border-radius: $border-radius;
        cursor: pointer;

        &--phone {
            display: none;
        }

        svg {
            @include size(50px);
            fill: $color-white;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-white;
            border-color: $color-white;

            svg {
                fill: $color-dark;
            }
        }

        &--search {
            &--disabled {
                pointer-events: none;
                opacity: .2;
            }
        }
    
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    header {

        & .header {
            &__container {
                padding-top: 55px;
                padding-bottom: 55px;
            }
        }

        &.header {
            &--fixed {

                & ~ main {
                    margin-top: 210px;
                }
            }
        }

        #headerImg {
            height: 210px;
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    header {

        #headerImg {
            display: none;
        }

        .header {
            &__container {
                align-items: flex-end;
            }
        }
    }

    .home {
        .header {
            .identity {
                &__title {

                    svg {
                        @include size(175px, 60px);
        
                        // path[fill="#0069A9"] {
                        //     fill: $color-white;
                        // }
        
                        // path[fill="#FEC700"] {
                        //     fill: $color-dark;
                        // }
        
                        // path[fill="white"] {
                        //     fill: $color-dark;
                        // }
        
                        // path[fill="#79B942"] {
                        //     fill: $color-dark;
                        // }
                    }

                }
            }
        }
    }

    .identity {

        &__title {
            @include size(175px, 60px);
            svg {
                @include size(175px, 60px);
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    header {

        .header {
            &__right {
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                padding: 15px calc(5% + 10px);
                justify-content: space-between;
                background: $color-main;
                z-index: 50;
                border-top: $border-width $border-style $color-gray;
            }

            &__container {
                justify-content: center;
                padding-top: 30px;
                padding-bottom: 30px;
            }
        }
    }

    .tools {
        &__item {
            @include size(40px);

            &--phone {
                display: block;
            }

            & svg {
                @include size(40px);
            }
        }
    }
}
