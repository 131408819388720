.cover {
    position: relative;
    min-height: 235px;

    &__container {
        position: relative;
        @include flex($direction: row, $wrap: wrap, $justifyContent: flex-end);
        column-gap: 30px;
        margin-bottom: 50px;
    }

    &__shape {
        z-index: 1;
        @include absolute($top: -30px, $right: -100px);
        @include size(150px, 130px);
        pointer-events: none;
    }

    &__image-wrapper {
        @include absolute($left: 0);
        width: calc(50% - 15px);
        min-height: 235px;
        height: 100%;
        background-color: $color-bg--image;
        border-radius: $border-radius;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }

        & ~ .cover__informations {
            width: calc(50% - 15px);
        }
    }

    &__informations {
        position: relative;
        width: 100%;
        height: fit-content;
        background-color: $color-light;
        padding: 40px;
        border-radius: $border-radius;
    
        
    }

    &__title {
        margin: 0 0 15px;
    }

    &__lead-wrapper {
        p {
            margin: 0;
            font-weight: $font-weight-bold;
            font-size: toRem(18);
            line-height: toRem(25);
        }
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .cover {

        &__container {
            gap: 20px;
        }
        
        &__image-wrapper {
            width: calc(50% - 10px);
        
            & ~ .cover {
                &__informations {
                    width: calc(50% - 10px);
                }
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {

    .cover {
        min-height: unset;

        &__container {
            flex-direction: column;
        }

        &__image-wrapper {
            position: initial;
            width: 100%;
            height: 400px;

            & ~ .cover {
                &__informations {
                    width: 100%;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .cover {
        &__image-wrapper {
            max-height: 300px;
        }
    }
}
