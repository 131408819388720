.footer {
    position: relative;
    padding: 100px 0 80px;
    background-color: $color-main;


    // Déco
    &__b {
        @include absolute($top: 45px, $right: calc(50% + 530px));
        @include size(270px, 285px);
        fill: $color-white;
        opacity: .1;
        pointer-events: none;
    }

    &__shape {
        @include absolute($top: -190px, $left: calc(50% + 480px));
        @include size(220px, 285px);
        pointer-events: none;
    }

    &__circle {
        @include absolute($bottom: -60px, $left: calc(50% + 640px));
        @include size(170px);
        fill: $color-gray;
    }
    // End Déco

    &__container {
        @include flex($wrap: wrap, $alignItems: center);
        gap: 150px;
    }

    &__logo {
        @include size(220px, 75px);
        fill: $color-white;
        margin-bottom: 50px;
    }

    &__infos {
        @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-end);
        z-index: 1;

        &--3 {
            .footer__btn {
                border-color: $color-gray;

                &:hover, &:focus {
                    background-color: $color-gray;
                    border-color: $color-gray;
                }
            }
        }
    }

    &__title {
        color: $color-white;
        @include font-size(25);
        line-height: toRem(30);
        font-weight: $font-weight-bold;
        margin-bottom: 5px;
        font-family: $font-family--heading;
    }

    &__text {
        color: $color-white;
        font-size: toRem(16);
        line-height: toRem(25);
        margin-bottom: 40px;
    }

    &__buttons {
        @include flex($direction: row, $wrap: wrap, $alignItems: center, $justifyContent: flex-start);
        gap: 10px;
    }

    &__btn {
        height: 50px;
        color: $color-white;
        border-color: $color-white;
        background-color: $color-bg--transparent;
        margin: 0;

        svg {
            @include size(20px);
            margin-right: 10px;
        }

        &:hover, &:focus {
            color: $color-text;
            border-color: $color-third;
        }
    }

    &__menu {
        height: 25px;
        margin: 60px 0 0;

        &__list {
            @include flex($wrap: wrap, $alignItems: center, $justifyContent: space-between);
            width: 100%;
        }

        &__item {
            position: relative;
            padding: 0;

            &::before {
                content: '';
                @include absolute($top: 50%, $right: -32px);
                @include size(8px);
                border: $border-width $border-style $color-gray;
                border-radius: $border-radius--round;
                transform: translateY(-50%);
            }

            &:last-child::before {
                content: none;
            }
        }

        &__link {
            font-family: $font-family--heading;
            font-size: toRem(16);
            line-height: toRem(25);
            color: $color-white;
            text-decoration: underline;
            text-decoration-color: $color-bg--transparent;
            text-underline-offset: 4px;
            transition: text-decoration-color $duration $timing;
            
            &:hover, &:focus {
                color: $color-white;
                text-decoration-color: $color-white
            }
        } 
    }
}

.single {
    .footer {
        &__shape {
            display: none;
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .footer {

        &__container {
            gap: 55px;
        }

        &__b {
            right: calc(50% + 415px);
        }

        &__shape {
            left: calc(50% + 395px);
        }

        &__circle {
            left: calc(50% + 510px);
        }

        &__title {
            font-size: toRem(22);
            line-height: toRem(25);
        }

        &__infos {

            &--1 {
                margin-right: 45px;
            }
        }

        &__menu {

            &__item {
                &:before {
                    right: -22px;
                }
            }

            &__link {
                font-size: toRem(14);
                line-height: toRem(20);
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .footer {
        padding: 80px 0;

        &__container {
            gap: 30px 80px;
        }

        &__infos {

            &--1 {
                width: 100%;
            }
        }

        &__text {
            margin-bottom: 30px;
        }

        &__shape {
            @include size(155px, 200px);
            top: -145px;
            left: calc(50% + 285px);
        }

        &__circle {
            left: calc(50% + 250px);
            bottom: -90px;
        }

        &__menu {
            height: unset;

            &__list {
                gap: 20px 38px;
                justify-content: flex-start;
            }

            &__item {
                &:before {
                    right: -24px;
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .footer {
        padding: 80px 0 150px;

        &__circle {
            @include size(120px);
            bottom: 20px;
            left: calc(50% + 205px);
        }
    }
}


// 360
@media screen and (max-width: $x-small) {

    .footer {

        &__circle {
            left: calc(50% + 105px)
        }
    }
}