.bloc-composition {
    position: relative;
    @include flex($alignItems: center);
    margin: 60px 0;

    &__image {
        flex: none;
        @include size(calc(50% + 85px), 400px);
        border-radius: $border-radius;
        overflow: hidden;
    }

    &__content {
        position: relative;
        z-index: 1;
        @include flex($direction: column, $alignItems: flex-start, $justifyContent: center);
        gap: 10px;
        width: 100%;
        min-height: 300px;
        padding: 45px 40px;
        background-color: $color-light;
        border-radius: $border-radius;
        box-shadow: unset;
    }

    picture, img {
        @include size(100%);
        object-fit: cover;
        object-position: top;
    }

    &__title {
        margin: 0;
        padding: 0;
        border: 0;
        font-family: $font-family--heading;
        font-weight: $font-weight--heading;
        font-size: $font-size--3;
        line-height: $line-height--heading;
        
        &::before, &::after {
            display: none;
        }
    }

    &__button {
        margin: 10px 0 0 0;

        &:hover, &:focus {
            background-color: $color-second;
            border-color: $color-second;
            color: $color-white;
        }
    }

    &--left {
        .bloc-composition__content {
            margin-left: -30px;
        }
    }

    &--right {
        .bloc-composition__content {
            margin-right: -30px;
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .bloc-composition {
        &__image {
            @include size(calc(50% + 70px), 320px);
        }

        &__content {
            min-height: 250px;
            padding: 30px;
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .bloc-composition {
        &__image {
            @include size(100%, 300px);
        }

        &--left {
            flex-direction: column;
        }
    
        &--right {
            flex-direction: column-reverse;
        }

        &__content {
            min-height: unset;
            width: auto;
            margin: -30px 20px 0 20px !important;
            padding: 30px;
        }
    }

}


// 640
@media screen and (max-width: $small) {

    .bloc-composition {
        &__image {
            height: 180px;
        }

        &__content {
            margin: -30px 10px 0 10px !important;
            padding: 20px;
        }
    }

}
