.nav-main--burger {
    z-index: inherit;
    background-color: $color-main;

    
    // Burger menu Button
    .nav-main-buttons {
        position: relative;

        &__menu {
            // @include absolute($bottom: 0, $right: 15px);
            z-index: 11;
            @include flex($justifyContent: flex-start, $alignItems: center);
            column-gap: 5px;
            width: fit-content;
            margin: 0;
            padding: 10px 20px;
            background-color: $color-third;
            border: 0;
            border-radius: $border-radius;
            transition: {
                property: background-color, color, opacity, visibility, pointer-events;
                duration: $duration;
                timing-function: $timing;
            }

            &__svg {
                fill: $color-text;
                @include size(30px);
            }
            
            cursor: pointer;

            &__text {
                font-weight: $font-weight-extrabold;
                font-size: toRem(18);
                line-height: toRem(25);
                color: $color-text;
                text-transform: uppercase;
            }

            &:hover, &:focus {
                background-color: $color-white;
            }

            // Open menu
            &--open {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;

                // Burger menu Button icon
                .nav-main-buttons__menu__icon {
                    position: relative;
                    top: -1px;
                    display: block;
                    @include size(20px, 2px);
                    background-color: $color-white;

                    &::before, 
                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        @include size(100%);
                        background-color: $color-white;;
                        transition: {
                            property: width, top, left, transform;
                            duration: $duration;
                            timing-function: $timing;
                        }
                    }

                    &::before {
                        top: 7px;
                    }

                    &::after {
                        top: -7px;
                    } 
                }
            }

            // Close menu
            &--close {
                @include absolute($top: -60px, $right: 0);
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                background-color: $color-white;
                padding: 10px;

                svg {
                    @include size(30px);
                    fill: $color-text;
                }

                &:hover, &:focus {
                    background-color: $color-third;

                    svg {
                        fill: $color-text;
                    }
                }
            }
        }

        // For responsive
        &__submenu {
            display: none;
        }

    }

    // Popup menu
    &__popup {
        @include fixed($top: -100vh, $left: 0, $right: 0);
        z-index: 10;
        display: block;
        max-width: inherit;
        @include size(100%, 100%);
        min-height: 100vh;
        background-color: $color-main;
        padding: 0;
        transition: {
            property: top, left;
            duration: .5s, $duration;
            timing-function: $timing;
        }
    }

    // Section menu (for overflow)
    &__section {
        position: relative;
        overflow-y: auto;
        height: 100%;
        padding: 40px 0 20px 0;

        &:before {
            content: "";
            @include absolute($top: 0, $bottom: 0, $left: calc(50% - 135px));
            @include size(1px, 100%);
            background-color: $color-gray;
            opacity: .5;
        }
    }

    // Container menu
    &__container {
        @include flex($justifyContent: space-between, $alignItems: flex-start);
    }

    // if Burger menu open
    &.js-open-menu {

        // Burger menu Button
        .nav-main-buttons__menu {

            // Close menu
            &--close {
                opacity: 1;
                visibility: initial;
                pointer-events: auto;
            }

            // Open menu
            &--open {
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
            }
        }

        // Popup menu
        .nav-main--burger__popup {
            top: 0;
            transition: top .7s cubic-bezier(0.4, 0, 0.2, 1);
        }

        // Main menu
        .menu {
            position: relative;
            display: flex;
        }

    }

    // Main menu
    .menu {
        display: none;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 5px;
        @include size(calc(50% - 185px), auto);
        margin-top: 75px;
        margin-bottom: 40px;
        padding: 0 50px 0 0;
        gap: 30px;

        &__item {
            @include flex($alignItems: center, $justifyContent: space-between);
            width: auto;

            &.menu-item-has-children {
                .menu__link {
                    &::after {
                        content: unset;
                    }

                    &:hover, &:focus {
                    
                        .menu {
                            &__link {
                                &__arrow {
                                    background-color: $color-third;
                                }
                            }
                        }

                        &:before {
                            width: calc(100% - 30px);
                        }
                    }
                }

                &.js-open-submenu {

                    .menu {
                        &__link {
                            &__arrow {
                                background-color: $color-third;
                            }

                            &:before {
                                width: calc(100% - 30px);
                            }
                        }
                    }

                    .submenu {
                        display: block;
                    }
                }
            }
        }

        &__link {
            position: relative;
            @include flex($alignItems: center, $justifyContent: space-between);
            max-width: inherit;
            @include size(auto, auto);
            padding: 0 0 5px;
            color: $color-white;
            font-weight: $font-weight-bold;
            text-transform: uppercase;

            &__arrow {
                flex: none;
                @include size(20px);
                fill: $color-dark;
                background-color: $color-white;
                border-radius: $border-radius--round;
                margin-left: 10px;
                transition: background-color $duration $timing;
            }

            &::before {
                content: '';
                @include absolute($left: 0, $bottom: 0);
                @include size(0, 2px);
                background-color: $color-third;
                transition: width $duration $timing;
            }

            &:hover, &:focus {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    // Sub menu
    .submenu {
        display: none;
        @include absolute($top: 0, $left: calc(100% + 150px), $right: inherit);
        z-index: inherit;
        width: 600px;
        padding: 0;
        background-color: $color-bg--transparent;
        box-shadow: none;

        &__nav {
            @include flex($direction: column, $wrap: wrap, $alignItems: flex-start, $justifyContent: flex-start);
            gap: 30px;
            max-width: inherit;
            // max-height: 80vh;
            padding: 0;
        }

        &__item {
            width: auto;
            margin: 0;         
        }

        &__link {
            position: relative;
            display: flex;
            align-items: center;
            @include size(auto);
            margin-bottom: 1px; // Fix space beetween inline-flex
            padding: 0 0 0 15px;
            margin: 0;
            border: 0;
            color: $color-white;
            font-weight: $font-weight-medium;
            transition: margin $duration $timing;

            &:before {
                content: "";
                @include size(5px);
                @include absolute($top: 0, $bottom: 0, $left: 0);
                margin: auto 0;
                background-color: $color-white;
                border-radius: $border-radius--round;
                transition: background-color $duration $timing;
            }

            &:hover, &:focus {
                margin-left: 10px;

                &:before {
                    background-color: $color-fourth;
                }
            }

            // &--child {
            //     &::before {
            //         content: '';
            //         display: inherit;
            //         @include absolute($bottom: 0);
            //         @include size(0, 1px);
            //         background-color: $color-white;
            //         transition: width $duration $timing;
            //     }

            //     &:hover, &:focus {
            //         &::before {
            //             width: 100%;
            //         }
            //     }
            // }

            // &--grandchild {
            //     display: flex;
            //     padding: 17px 10px 17px 15px;
            //     border-bottom: 0;
            //     font-size: $font-size--text-small;
            //     font-weight: $font-weight;
            //     text-transform: initial;
            //     line-height: initial;
            // }
        }
    }

}

// Button accessibility
button.avoid-main-content {
    @include absolute($bottom: 40px, $right: 40px);
    pointer-events: none;

    &:focus {
        color: $color-white;
        border-color: $color-white;
    }
}

// For no scroll on body when menu is open
body.js-open-menu {
    overflow: hidden;
}


//=================================================================================================
// Header only for burger menu on demosaisie
//=================================================================================================
// Tools
.header .tools--burger {
    top: auto;
}

// Identity
.identity.identity--burger {
    position: relative;
    z-index: 10;
    width: fit-content;
    height: auto;
    max-width: 85%;

    .identity__title {
        transition: {
            property: margin-top, color;
            duration: $duration;
            timing-function: $timing;
        }
    }
}

body.js-open-menu {
    .identity__title {
        color: $color-white;
    }
}


//=================================================================================================
// Responsive
//=================================================================================================

// 1200
@media screen and (max-width: $large) {

    .nav-main--burger {

        // Sub menu
        .submenu {
            width: 540px;
            left: calc(100% + 100px);

            &__nav {
                gap: 20px;
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {
    
    // Header -- Identity
    .identity.identity--burger {
        position: inherit;
        z-index: inherit;
        max-width: 100%;
        height: auto;
    }
    body.js-open-menu .identity__title {
        margin-top: 0;
        color: $color-text;
    }

    
    // Menu
    .nav-main--burger {
        position: initial;
        height: auto;

        &__section {
            &:before {
                content: none;
            }
        }

        // if Burger menu open
        &.js-open-menu {

            // Popup menu
            .nav-main--burger__popup {
                left: 0;
                transition: left $duration $timing;
            }

            .nav-main-buttons {
                &__menu {
                    &--close {
                        height: 50px;
                        top: 65px;
                        right: calc(5% + 10px);
                    }
                }
            }

            // And If submenu open
            .nav-main-buttons.js-open-submenu {
                .nav-main-buttons__submenu {
                    left: calc(5% + 10px);
                }
            }
        }

        // If submenu open
        &.js-open-submenu {
            .nav-main--burger__section {
                overflow: hidden;
            }
        }

        // Burger Buttons
        .nav-main-buttons {
            position: inherit;
            @include flex($alignItems: center, $justifyContent: flex-start);

            // Open menu
            &__menu {

                // Close menu
                &--close { 
                    @include absolute($bottom: 0, $right: 0, $top: 0);
                    @include flex($alignItems: center, $justifyContent: center);
                }

            }

            // Burger menu responsive - Close submenu
            &__submenu {
                @include absolute($top: 65px, $left: -100vw);
                z-index: 11;
                @include flex($alignItems: center, $justifyContent: center);
                flex-shrink: 0;
                @include size(50px);
                margin: 0;
                padding: 0;
                background-color: $color-white;
                border: 0;
                border-radius: $border-radius;
                transition: all $duration $timing;

                svg {
                    @include size(25px);
                    fill: $color-text;
                    transform: rotate(180deg);
                }

                &:hover, &:focus {
                    background-color: $color-third;
                }
            }
        }

        // Popup menu
        &__popup {
            top: 0;
            right: inherit;
            left: -100vw;
            padding: 0;

            &::before {
                height: 100px;
            }
        }

        // Section menu
        &__section {
            padding: 0;
        }

        // Container menu
        &__container {
            max-width: initial;
            margin: 0;
            padding: 0;
        }

        // Main menu
        .menu {
            flex-wrap: nowrap;
            @include size(100%, auto);
            min-height: 100vh;
            margin: 0;
            padding: 140px 40px 40px;
            gap: 10px;
            overflow: hidden;

            &::after {
                display: none;
            }

            &__item {
                &.menu-item-has-children {

                    &:focus-within .submenu,
                    &:hover .submenu {
                        display: flex;
                    }

                    &.js-open-submenu {
                        .submenu {   
                            left: 0;
                            display: flex;
                        }
                    }

                    .menu__link::after {
                        display: none;
                    }
                }
            }

            &__link {
                &::before {
                    display: none;
                }
            }
        }

        // Sub menu
        .submenu {
            @include fixed($top: 0, $right: inherit, $left: -100vw);
            z-index: 1;
            display: flex;
            flex-direction: column;
            row-gap: 30px;
            @include size(100vw, 100vh);
            padding: 140px 0 0;
            background-color: $color-main;
            transition: left $duration $timing;

            &::after {
                display: none;
            }
    
            &__nav {
                @include flex($direction: column, $justifyContent: flex-start, $wrap: nowrap);
                gap: 10px;
                @include size(100%, calc(100vh - 100px));
                max-width: 100%;
                max-height: inherit;
                overflow-y: auto;
                padding: 5px calc(5% + 10px) 40px;
            }

            &__item {
                width: 100%; 
            }

            &__link {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: $small) {

    .nav-main {
        &--burger {
            .nav-main-buttons {
                &__menu {
                    &--open {
                        padding: 5px 15px;
                    }
                }

                &__menu--close, &__submenu {
                    position: fixed;
                }

                &__menu {
                    &--close {
                        height: 50px;
                        right: calc(5% + 10px);
                        top: 65px;
                    }
                }
            }
        }
    }
}